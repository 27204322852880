@import url('https://fonts.googleapis.com/css2?family=Lora&family=Open+Sans:wght@300;400;600&family=Share+Tech+Mono&display=swap');

.bg-pane {
  background: rgb(0, 0, 8);
  background: linear-gradient(
    121deg,
    rgba(0, 0, 8, 1) 0%,
    rgba(158, 45, 0, 1) 100%
  );
}
